// import router from "../../router";
import LoginService from "@/services/LoginService";

import notify from 'devextreme/ui/notify';

const state = {
  loggingIn: false,
  loginError: null,
  loginSuccessful: false,
  loginUser : null
};

const getters = {
  loginUser: state => {
      return state.loginUser;
  },
};

const mutations = {
  loginStart: state => (state.loggingIn = true),
  loginStop: (state, errorMessage) => {
    state.loggingIn = false;
    state.loginError = errorMessage ? errorMessage.message : null;
    state.loginSuccessful = !errorMessage;
  },
  loginUser: (state, loginUser) => state.loginUser = loginUser
};

const actions = {
  doLogin({ commit }, loginData) {
    commit("loginStart");

    LoginService.login(
      loginData,
      function(data) {
        // eslint-disable-next-line no-console
        var dataUser = data.data;
        if (dataUser.status) {
          if (dataUser.token) {
            var user = {
              username : loginData.username,
              token : dataUser.token,
              expired_at : dataUser.expired_at.date
            };
            commit("loginUser", JSON.stringify(user));

            commit("loginStop", null);
            window.location = process.env.VUE_APP_BASE_API_URL;
            
          }
        } else {
          // eslint-disable-next-line no-console
          notify({
              message: dataUser.message,
              type: 'error',
              displayTime: 2500,
              maxWidth : '45px',
              position : {
                at : 'center center',
                my : 'center center',
                of : '#mainContent',
                offset : '0 -200'
              }
          });
        }
      },
      function(error) {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.log(error);
          commit("loginStop", error.response.data.error);
        }
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
