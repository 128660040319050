import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import userlogin from "./modules/userlogin";
import users from "./modules/users";

// import createPersistedState from 'vuex-persistedstate';
// import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  // plugins: [createPersistedState({
  //   key: 'ljr-user',
  //   paths: ['auth.loginUser'],
  //   storage: {
  //     getItem: key => Cookies.get(key),
  //     setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: false }),
  //     removeItem: key => Cookies.remove(key)
  //   }
  // })],
  mutations: {
    startLoading: (state) => (state.loading = true),
    stopLoading: (state) => (state.loading = false),
  },
  actions: {
    load({ commit }) {
      commit("startLoading");
    },
    doneLoad({ commit }) {
      commit("stopLoading");
    },
  },
  modules: {
    auth,
    userlogin,
    users,


  },
});
