const state = {
  userlogin: null,
  userLoginStatus: null,
};

const getters = {
  getUserLogin: (state) => {
    return state.user;
  },
  getUserLoginStatus: (state) => {
    return state.userStatus;
  },
};

const mutations = {
  setUserLogin: (state, user) => {
    state.user = user;
  },
  clearUserLogin: (state) => {
    state.user = null;
  },
  setUserLoginStatus: (state, userStatus) => {
    state.userStatus = userStatus;
  },
  clearUserLoginStatus: (state) => {
    state.userStatus = null;
  },
};

const actions = {
  setUserLogin({ commit }, user) {
    commit("setUserLogin", user);
  },
  clearUserLogin({ commit }) {
    commit("clearUserLogin");
  },
  setUserLoginStatus({ commit }, userStatus) {
    commit("setUserLoginStatus", userStatus);
  },
  clearUserLoginStatus({ commit }) {
    commit("clearUserLoginStatus");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
