
import Welcome from "./views/welcome";
// import WelcomeManager from "./views/WelcomeManager.vue";
// import WelcomeAdmin from "./views/WelcomeAdmin.vue";
// import WelcomeSuperAdmin from "./views/WelcomeSuperAdmin.vue";

import Vue from "vue";
import Router from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";

import simpleLayout from "./layouts/single-card";

var moment = require("moment");
Vue.use(Router);

// var currentLayout = defaultLayout;
// window.location.reload()
// var authUser = JSON.parse(window.localStorage.getItem("lbUser"));
var authUser = localStorage.getItem("roleid");
console.log("Tipe User : " + authUser);

const router = new Router({
  routes: [
    //---------------- login----------------
    {
      path: "/",
      redirect: "/progress",
    },
    {
      path: "*",
      redirect: "/progress",
    },
    {
      path: "/login",
      name: "login",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/login-form"),
      },
    },
    {
      path: "/progress",
      name: "progress",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "progress" */ "./views/progress"),
      },
    },
    {
      path: "/logout",
      name: "logout",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/logout"),
      },
    },
    //---------------- welcome----------------
    // {
    //   path: "/welcome-super-admin",
    //   name: "welcome-super-admin",
    //   meta: { requiresAuth: true, superadminAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     // layout: currentLayout,
    //     content: WelcomeSuperAdmin,
    //   },
    // },
    // {
    //   path: "/welcome-admin",
    //   name: "welcome-admin",
    //   meta: { requiresAuth: true, adminAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     // layout: currentLayout,
    //     content: WelcomeAdmin,
    //   },
    // },

    // {
    //   path: "/welcome-manager",
    //   name: "welcome-manager",
    //   meta: { requiresAuth: true, managerAuth: true },
    //   components: {
    //     layout: currentLayout,
    //     // layout: currentLayout,
    //     content: WelcomeManager,
    //   },
    // },
    {
      path: "/welcome",
      name: "welcome",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Welcome,
      },
    },

    //

  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
    if (!authUser || !authUser.token) {
      next({ name: "login" });
    } else if (to.meta.superadminAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (authUser.data.user.role_id == "1") {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else if (to.meta.managerAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (
        authUser.data.user.role_id == "1" ||
        authUser.data.user.role_id == "2"
      ) {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else if (to.meta.adminAuth) {
      const authUser = JSON.parse(window.localStorage.getItem("lbUser"));
      if (authUser.data.user.role_id == "4") {
        console.log("router role_id : " + authUser.data.user.role_id);
        next();
      } else {
        next("/welcome");
      }
    } else {
      // Token Expiration Check
      const lastLoginDate = moment(authUser.last_login_date);
      const nowDate = moment();

      var duration = moment.duration(nowDate.diff(lastLoginDate));
      var hours = duration.asHours();

      if (hours > 8) {
        next({ name: "login" });
        // window.location = process.env.VUE_APP_AUTH_GATEWAY_URL+'/#/login-form?appId=5&company=62a5f1ac-8a42-11ea-88fd-3c2c30ee3c63';
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
