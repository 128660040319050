import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import Vue from "vue";

import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import appVersion from "./app-version";
import store from './store/index';
import moment from 'moment'

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.prototype.$appVersion = appVersion;
Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

new Vue({
  router,
  store,
  filters: {
    unescape: v => unescape(v)
  },
  render: h => h(App)
}).$mount("#app");
