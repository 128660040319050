export default [
  {
    text: "Cek Kendaraan",
    icon: "car",
    items: [
      {
        text: "Kedatangan",
        icon: "columnchooser",
        path: "/arrival/vehicle/check",
      },
      {
        text: "Keberangkatan",
        icon: "columnchooser",
        path: "/departure/vehicle/check",
      },
    ],
  },
  {
    text: "Berita Acara",
    icon: "file",
    path: "/vehicle/report/damage",
  },
  
];
