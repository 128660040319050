const state = {
  User: null,
  UserStatus: null,
  UserForm: null,
};

const getters = {
  getUser: (state) => {
    return state.User;
  },
  getUserStatus: (state) => {
    return state.UserStatus;
  },
  getUserForm: (state) => {
    return state.UserForm;
  },
};

const mutations = {
  setUser: (state, User) => {
    state.User = User;
  },
  clearUser: (state) => {
    state.User = null;
  },

  setUserStatus: (state, status) => {
    state.UserStatus = status;
  },
  clearUserStatus: (state) => {
    state.UserStatus = "";
  },

  setUserForm: (state, status) => {
    state.UserForm = status;
  },
  clearUserForm: (state) => {
    state.UserForm = "";
  },
};

const actions = {
  setUser({ commit }, data) {
    commit("setUser", data);
  },
  clearUser({ commit }) {
    commit("clearUser");
  },

  setUserStatus({ commit }, status) {
    commit("setUserStatus", status);
  },
  clearUserStatus({ commit }) {
    commit("clearUserStatus");
  },

  setUserForm({ commit }, data) {
    commit("setUserForm", data);
  },
  clearUserForm({ commit }) {
    commit("clearUserForm");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
